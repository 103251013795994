
.document-container {
    margin-bottom: 16px;
}

.textLayer span,
.textLayer a {
    color: transparent !important;
    text-shadow: none !important;
    line-height: unset !important;
    translate: 0 -1.5px;
}

.textLayer a {
    font-family: inherit !important;
}

.document-controls {
    font-size: 20px !important;
}

.document-controls button {
    min-width: clamp(90px, 20vw, 140px);
}

.document-controls .page-state {
    font-size: clamp(10px, 3vw, 20px) !important;
}