.App {
  text-align: center;
  min-height: 100vh;
}

html,body
{
  min-width: 450px !important;
  width: 100% !important;
  background-color: #222 !important;
}

.comment-color {
  background-color: #01831b !important;
}

.comment-border-color {
  border-style: solid !important;
  border-width: 4px  !important;
  border-color: #013b1d !important;
}

.comment-color:nth-child(even) {
  background-color: #015c13 !important;
}

.comment-border-color:nth-child(even) {
  border-color: #011e0f !important;
}

.comment-message-color {
  background-color: #015f1d !important;
}

.comment-message-color::placeholder {
  font-style: italic;
}

.comment-empty {
  background-color: dimgray;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
}

.rpgui-content textarea:read-only::placeholder {
  color: darkgray;
}

.rpgui-content textarea {
  padding-top: 12px !important;
  padding-left: 12px !important;
}

.App-link {
  color: #61b6fb;
}

.SnackbarContainer-root {
  min-width: 150px;
  z-index: 30000 !important;
}

.SnackbarItem-message {
  white-space: pre;
  white-space: break-spaces;
  min-width: 150px;
  font-size: clamp(9px, 2.1vw, 15px);
}

.SnackbarItem-action {
  position: absolute;
  right: 0px;
  margin: 0px !important;
  padding: 0px !important;
}

.nes-balloon, .nes-balloon::before, .nes-balloon::after {
  background-color: dimgray !important;
}

.nes-balloon.from-left::after {
  box-shadow: -4px 0, 4px 0, -4px 4px #504f4f, 0 4px, -8px 4px, -4px 8px, -8px 8px !important;
}

.nes-balloon.from-right::after {
  box-shadow: -4px 0, 4px 0, 4px 4px #504f4f, 0 4px, 8px 4px, 4px 8px, 8px 8px !important;
}

.select-none,
.select-none * {
  user-select: none !important;
}

.select-text,
.select-text * {
  user-select: text !important;
}

a:hover {
  color: goldenrod !important;
}

a {
  color: yellow !important;
}

.delete-button {
  height: 16px !important;
  line-height: 8px !important;
  width: 16px !important;
  min-width: 16px !important;
  padding: 0px !important;
  border-width: 3.5px !important;
  padding-left: 1px !important;
}

.delete-button * {
  font-size: x-small !important;
}

.delete-button::after {
  box-shadow: none !important;
}

.comment-delete {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
}

.auto-size {
  width: 100% !important;
  max-width: min(600px, calc(100vw - 20px));
  min-height: 2rem;
}

.comment-submit {
  align-items: center;
  display: flex;
  justify-content: center;
}

.comment-message {
  display: inline-block !important;
  width: 100% !important;
  min-height: 3em !important;
}

.fit-content {
  width: fit-content !important;
}

.full-width {
  width: 100% !important;
}

.comments {
  margin-bottom: 10px;
  max-width: 1200px;
  table-layout: fixed;
  min-width: 300px;
}

table.fixed {
  table-layout: fixed;
}

table.fit {
  display: inline-flex;
}

.comment-root {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
}

.comment-metadata {
  align-items: center !important;
  margin: 5px !important;
  background-color: green;
  height: fit-content !important;
  max-width: 100vw;
  min-width: 250px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
}

.comment-balloon,
.comment-balloon::before,
.comment-balloon::after {
  box-shadow: 15px 15px rgba(0, 0, 0, 0.2);
}

.no-wrap {
  white-space: nowrap;
}

.show-whitespace {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-paragraph-margin p {
  margin-bottom: 0;
}

.display-inline-flex {
  display: inline-flex !important;
}

.comment-metadata .super-font {
  line-height: 0 !important;
  margin-bottom: -22px;
}

.super-font-container {
  height: 2.5em;
  display: inline-flex;
  align-items: flex-end;
}

label.super-font {
  align-self: flex-start;
  margin-left: -10px;
  position: absolute;
}

.super-font {
  vertical-align: super;
  font-size: 8px !important;
  color:rgb(188, 188, 188) !important;
}

.hover-button-color .rpgui-icon:hover {
  background-color: goldenrod;
}

:disabled,
.disabled,
[disabled],
:disabled *,
.disabled *,
[disabled] * {
  pointer-events: none !important;
  color:rgb(128, 128, 128) !important;
}

.MuiAvatar-root {
  display: inline-flex !important;
  font-family: 'Press Start 2P', cursive !important;
  font-size: 14px !important;
}

.amplify-alert--error {
  display: inline-flex;
  align-items: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}

.amplify-alert__body {
  font-size: 8px;
}

.amplify-button--primary.rpgui-button.golden {
  width: calc(100% - 20px);
}

.amplify-flex-center {
  justify-content: center !important;
  align-items: center !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-container {
  z-index: 10500 !important;
  width: initial !important;
  height: initial !important;
}

.tooltip-container.MuiTooltip-popper[data-popper-placement*="bottom"] {
  inset: 30px auto auto 0px !important;
}

.tooltip-text {
  z-index: 9999 !important;
  text-align: center;
  border-radius: 6px;
}

.rpgui-dropdown-imp {
  align-self: center;
  flex-direction: row;
  display: flex;
  white-space: pre;
}

.scrollbar-view {
  scrollbar-width: none;
  margin: 0 !important;
}

.scrollbar-track {
  z-index: 10000;
}

.scrollbar-track.track-horizontal {
  position: absolute;
  height: 6px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
  margin-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.scrollbar-track.track-vertical {
  position: absolute;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  margin-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.scrollbar-thumb.thumb-horizontal {
  position: relative;
  display: block;
  width: 100px;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
}

.scrollbar-thumb.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 496px) {
  .amplify-flex-optional {
    display: flow-root !important;
  }

  h1 {
    font-size: 2.5em !important;
  }

  .comment-metadata-container img {
    width: 100%;
  }

  span.initial-1 {
    max-width: 1.8em;
    display: inline-flex;
    text-overflow: ellipsis;
    overflow-x: hidden;
    position: relative;
    letter-spacing: 1em;
  }

  span.initial-1::after {
    content: ".";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    letter-spacing: 0;
  }

  .rpgui-filters > * {
    width: 90vw;
  }
}

@media (max-width: 400px) {
  html,body
  {
    transform: scale(width / 400px) !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .animation-spin {
    animation: animation-spin infinite 20s linear;
  }
  .noise {
    animation: noise 6s steps(8,end) infinite both;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes animation-spin {
  /* FIXME: hardcoded transform-origin for job icon specifically */
  from {
    transform: rotate(0deg);
    transform-origin: 18.5px 4.5px;
  }
  to {
    transform: rotate(360deg);
    transform-origin: 18.5px 4.5px
  }
}

.animation-wiggle-repeat {
  animation-name: animation-wiggle;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.animation-wiggle-once  {
  animation-name: animation-wiggle;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.animation-wiggle-hover:hover {
  animation-name: animation-wiggle;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes animation-wiggle {
  0% {rotate: 10deg;}
  25% {rotate: -10deg;}
  50% {rotate: 20deg;}
  75% {rotate: -5deg;}
  100% {rotate: 0deg;}
}

/* https://loading.io/css/ */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
}

.lds-dual-ring:after,
.slow-spin {
  animation: slow-spin 1.2s linear infinite;
}

@keyframes slow-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-blocks {
  0% { background: #41c316 }
  12.5% { background: #41c316 }
  12.625% { background: #854c30 }
  100% { background: #854c30 }
}
.loading-blocks-container div {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #854c30;
  animation: loading-blocks 1s linear infinite;
}
.loading-blocks {
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading-blocks-container {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading-blocks-container div { box-sizing: content-box; }

.rpgui-project .portrait {
  width: 200px;
  margin: auto;
}

.super-font .rpgui-dropdown-imp-header {
  height: 40px;
}

.super-font ul.rpgui-dropdown-imp {
  margin-top: 40px !important;
}

.rpgui-button.golden.lg {
  text-align: center;
  padding-top: 20px;
}

.rpgui-button.golden.lg::before,
.rpgui-button.golden.lg::after {
  height: 180%;
  margin-top: -20px;
}

.rpgui-button .amplify-icon {
  width: 32px !important;
  height: 32px !important;
  text-align: center;
}

.rpgui-content .rpgui-container button[data-state="inactive"]  {
  background-color: #69696973 !important;
}

.rpgui-content .rpgui-container .amplify-field__show-password {
  width: 32px !important;
}

.rpgui-content .rpgui-container .amplify-field__show-password svg {
  display: none !important;
}

.rpgui-content .rpgui-container .button-link {
  color :goldenrod;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

.rpgui-content .is-tiny-text {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.rpgui-content .rpgui-container button:not(.rpgui-button):hover {
  background-color: #7f7f7f73 !important;
}

.rpgui-content .rpgui-container button[role="tab"] {
  border-color: #7f7f7f73 !important;
}

.rpgui-content .rpgui-container li:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.MuiPaper-root div[role="dialog"] {
  background-color: #2d2d2d !important;
}

.MuiDialogContent-root {
  padding-bottom: 6px !important;
}

.rpgui-dialog-root {
  z-index: 10000 !important;
}

.visibility-hidden {
  visibility: hidden;
}

.dialog-container {
  min-width: 350px;
  max-width: 80vw !important;
}

.navigation-bar,
.see-through-background:not(:hover) {
  background-color: #2d2d2dea !important;
}

.has-box-shadow {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.is-dark:not(:hover),
.is-dark.hover {
  background-color: #212529 !important;
}

.is-sticky {
  position: sticky !important;
}

.thin-frame {
  border-image-width: 12px !important;
  border-width: 8px !important;
}

.tiny-frame {
  border-image-width: 2 !important;
  border-image-outset: 1 !important;
  padding: 0px 2px !important;
}

.nes-container.is-thin {
  border-width: 2px;
  border-radius: 4px;
}

.nes-icon.is-small {
  width: 24px;
  height: 24px;
  transform: scale(1.5) !important;
}

.nes-icon.is-tiny {
  width: 16px;
  height: 16px;
  transform: scale(1) !important;
}

.does-glow:hover {
  transition: all 0.2s ease-in-out;
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.8);
}

.does-glow.is-glow-rounded {
  border-radius: 50%;
}

.does-glow:hover i {
  mix-blend-mode: plus-lighter;
  mix-blend-mode: screen;
}

button.simple,
button.simple:hover,
.is-button.simple,
.is-button.simple:hover {
  background: none;
  height: fit-content;
  padding: 0 5px !important;
  min-width: fit-content;
}

button.simple:hover,
.is-button.simple:hover {
  background-color: gold;
  border-radius: 3px !important;
}

.navigation-overflow-menu-item {
  width: 120px;
  margin: 0 !important;
}

.navigation-overflow-menu-toolbar {
  gap: 10px;
}

.navigation-overflow-menu-container,
.navigation-overflow-menu-container.on-right {
  position: absolute;
  top: auto;
  right: 5px;
}

.navigation-overflow-menu-container.on-left {
  position: absolute;
  top: auto;
  left: 5px;
}

.settings-menu-container {
  top: 1.5em;
  right: 5px;
}

.navigation-overflow-menu-list {
  background-color: black;
}

.navigation-overflow-menu-header {
  width: 24px;
  height: 24px;
  box-shadow: none !important;
}

.navigation-overflow-menu-button {
  padding: 0 !important;
  margin: 0 0 5px 5px !important;
  visibility: hidden;
}

.navigation-overflow-button-icon {
  color: white;
}

button:hover svg,
.is-button:hover svg {
  color: black;
}

.nes-balloon, .nes-btn, .nes-container.is-rounded,
.nes-container.is-rounded.is-dark,
.nes-dialog.is-rounded,
.nes-dialog.is-rounded.is-dark,
.nes-progress,
.nes-progress.is-rounded, .nes-table.is-bordered,
.nes-table.is-dark.is-bordered,
.nes-input,
.nes-textarea,
.nes-select select {
  border-image-repeat: stretch !important;
}

.rpgui-content :disabled,
.rpgui-content :disabled + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-dropdown-imp + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-list-imp,
.rpgui-content input[type="checkbox"]:disabled + label,
.rpgui-content input[type="radio"]:disabled + label,
.rpgui-content input[type="range"]:disabled + .rpgui-slider-container,
.rpgui-disabled {
  mix-blend-mode: luminosity;
  color:rgb(188, 188, 188);
}

.show-overflow {
  overflow: auto !important;
}

.show-overflow-x {
  overflow-x: auto !important;
}

.show-overflow-y {
  overflow-y: auto !important;
}

.navigation-item:not(.active) a {
  color: unset !important;
}

.amplify-dialog {
  padding-right: 20px !important;
}

.rpgui-text {
  /* remove outline effect for input elements etc */
  outline: none;
  /* prevent dragging */
  /* user-drag: none; */
  -webkit-user-drag: none;
  /* prevent text selecting */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  /* pixelated enlargement filter (to keep the pixel-art style when enlarging pictures) */
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  /* default font */
  font-family: 'Press Start 2P', cursive !important;
}

.rpgui-text.is-tiny {
  font-size: 6px !important;
}

.rpgui-text.is-mini {
  font-size: 9px !important;
}

.rpgui-text.is-small {
  font-size: 12px !important;
}

.rpgui-text.is-medium {
  font-size: 16px !important;
}

.rpgui-text.is-large {
  font-size: 20px !important;
}

.noise-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.noise-container.is-tube::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
}

.noise {
  position: absolute;
  top: -200px;
  right: -200px;
  bottom: -200px;
  left: -200px;
  background: transparent url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
  background-size: 320px 320px;
	opacity: .35;
	animation: noise 3s steps(8,end) infinite both;
}

@keyframes noise {
  0% { transform: translateX(0px,0px); }
  10% { transform: translate(-100px, 100px); }
  20% { transform: translate(150px, -100px); }
  30% { transform: translate(-100px,100px); }
  40% { transform: translate(100px, -150px); }
  50% { transform: translate(-100px, 200px); }
  60% { transform: translate(-200px, -100px); }
  70% { transform: translateY(50px, 100px); }
  80% { transform: translate(100px, -150px); }
  90% { transform: translate(0px, 200px); }
  100% { transform: translate(-100px, 100px); }
}

.propogate-size {
  height: fit-content !important;
}

.propogate-size > *,
.propogate-size {
  padding: inherit !important;
  line-height: inherit !important;
}

.propogate-no-margin > *,
.propogate-no-margin {
  margin: 0 !important;
}

.has-pointer-events {
  pointer-events: auto !important;
}

.ignore-pointer-events {
  pointer-events: none !important;
}

.rpgui-icon.mini-me {
  background-image: url('assets/mini.png');
}

.pixelated {
  image-rendering: pixelated;
}

.left-arrow {
  rotate: 270deg;
  translate: 0px -2px;
}

.right-arrow {
  rotate: 90deg;
}

.up-arrow {
  rotate: 0deg;
}

.down-arrow {
  rotate: 180deg;
}

.cancel {
  translate: -1px 0px;
}

.question-mark {
  translate: 1px 0px;
}

.animation-selection-pulse {
  animation: selection-pulse 1s infinite alternate;
}

.animation-selection-pulse:hover {
  animation-play-state: paused;
}

:root {
  --border-size: clamp(3.5px, max(1vw, 1vh), 10px);
}

@keyframes selection-pulse {
  from {
    box-shadow: 0 0 var(--border-size) calc(var(--border-size) * -1px) gold;
  }
  to {
    box-shadow: 0 0 var(--border-size) var(--border-size) gold;
  }
}

.rpgui-tooltip-text,
.rpgui-tooltip-text * {
  font-size: clamp(7px, 1.75vw, 10px) !important;
}

.rpgui-tooltip-text {
  white-space: pre-wrap;
}
