.rich-text-editor-toolbar.ql-snow {
    background-color: snow;
    position: relative;
    z-index: 10;
}

.rich-text-editor-toolbar.ql-snow .ql-hidden {
    display: none !important;
}

.rich-text-editor-toolbar.ql-snow,
.rich-text-editor-toolbar .ql-picker-options {
    background-color: #d27d2c;
    border: black solid 2px;
}

.rich-text-editor-toolbar.ql-snow *:hover:not(.ql-custom) {
    color: goldenrod;
}

.rich-text-editor-toolbar.ql-snow .ql-custom div:hover,
.rich-text-editor-toolbar.ql-snow .ql-custom div {
    color: black;
}

.rich-text-editor-toolbar.ql-snow button:not(.ql-disabled, .ql-active) svg,
.rich-text-editor-toolbar.ql-snow span:not(.ql-disabled, .ql-active) svg {
    color: black;
    mix-blend-mode: multiply;
}

.rich-text-editor-toolbar.ql-snow *:not(.ql-disabled) svg * {
    color: black;
    stroke: black;
}

.ql-snow *:not(.ql-disabled) .ql-fill, .ql-snow *:not(.ql-disabled) .ql-stroke.ql-fill {
    fill: black;
}

.rich-text-editor-toolbar.ql-snow .ql-selected:not(:hover, *[aria-expanded="true"]),
.rich-text-editor-toolbar.ql-snow .ql-active svg:not(:hover, *[aria-expanded="true"]) * {
    color: yellow;
    stroke: yellow;
}

.rich-text-editor-toolbar.ql-snow button.ql-active {
    mix-blend-mode: overlay;
}

.rich-text-editor-toolbar.ql-snow .ql-formats > *:hover:not(.ql-picker-options, .ql-ignore-hover),
.rich-text-editor-toolbar.ql-snow .ql-formats :not(.ql-no-hover) .ql-picker-options > *:hover,
.rich-text-editor-toolbar.ql-snow .ql-formats .ql-exclusive-hover .ql-explicit-hover:hover,
.rich-text-editor-toolbar.ql-snow .ql-formats .ql-children-select > :hover,
.rich-text-editor-toolbar.ql-snow .ql-formats .ql-show-more:hover,
.rich-text-editor-toolbar.ql-snow .ql-formats button:hover {
    background-color: gold;
    border-radius: 2px;
}

.rich-text-editor-toolbar.ql-snow .ql-formats > *:hover:not(.ql-picker-options) {
    color: gold;
}

.rich-text-editor-toolbar.ql-snow .rpgui-cursor-default,
.rich-text-editor-toolbar.ql-snow .rpgui-cursor-default *,
.rich-text-editor-toolbar.ql-snow .rpgui-cursor-point {
    pointer-events: none !important;
}

.rich-text-editor-toolbar.ql-snow .rpgui-cursor-point *:not(.ql-disabled, .ql-formats, .ql-controls)  {
    pointer-events: all !important;
}

.ql-font--apple-system {
    font-family: '-apple-system', cursive !important;
}

.rich-text-editor-toolbar.ql-snow button.ql-custom {
    display: flex;
    align-items: center;
}

.rich-text-editor-toolbar.ql-snow .ql-icon-picker .ql-picker-label,
.rich-text-editor-toolbar.ql-snow .ql-color-picker .ql-picker-label {
    margin-top: -4px;
}

.rich-text-editor-toolbar.ql-snow button.ql-custom div {
    width: 24px;
    height: 24px;
}

.rich-text-editor-toolbar.ql-snow .ql-custom span.ql-picker-label {
    padding-right: 18px;
}

.rich-text-editor-toolbar.ql-snow .ql-formats > *,
.rich-text-editor-toolbar.ql-snow .ql-children-select > * {
    margin-right: 3px;
}

.rich-text-editor-toolbar.ql-snow .ql-disabled *,
.rich-text-editor-toolbar.ql-snow .ql-disabled .ql-custom:hover,
.ql-container.ql-disabled *::before  {
    pointer-events: none !important;
    color: darkgray !important;
}

.rich-text-editor-toolbar.ql-snow .ql-disabled svg {
    mix-blend-mode: hard-light;
}

.rich-text-editor-toolbar.ql-snow .ql-show-more {
    width: 28px;
    background-color: #d27d2c;
}

.ql-container .ql-tooltip.ql-toolbar-tooltip {
    box-shadow: none;
    color: white;
}

.ql-container .ql-tooltip.ql-toolbar-tooltip::before {
    content: none;
}

.ql-container.ql-snow .ql-editor.ql-blank::before {
    color: snow;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    line-height: 22px;
    font-size: 14px;
}

.ql-container.ql-snow {
    padding-bottom: 1em;
}

.text-editor {
    min-width: 300px;
    max-width: calc(100vw - 50px);
    position: relative;
}

.ql-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.ql-max-limit {
    color: red !important;
}

.ql-snow.ql-toolbar .ql-custom-picker {
    background-color: #d27d2c !important;
    border: #ccc solid 1px;
}

.ql-controls {
    position: relative;
    z-index: 30;
}

.ql-views {
    position: relative;
    z-index: 20;
}

.ql-formats:not(.not-indexed) {
    position: relative;
    z-index: -1;
}

.ql-snow.ql-toolbar button,
.ql-snow.ql-toolbar select,
.ql-snow.ql-toolbar .ql-picker {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    border-radius: 0;
}

.ql-snow .ql-tooltip .ql-action::before {
    content: ' ';
    display: block;
}

.ql-snow .ql-tooltip .ql-preview::before {
    content: ' ';
    display: block;
}

.ql-snow .ql-tooltip::before {
    margin-left: 10px;
}

.ql-snow .ql-tooltip {
    box-shadow: none;
    height: fit-content;
    max-width: 90vw;
}

.ql-snow .ql-tooltip input,
.ql-snow .ql-tooltip a.ql-preview {
    width: max-content !important;
    max-width: 55vw !important;
}

.ql-snow .ql-tooltip::before {
    color: goldenrod;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Link:";
}

.ql-snow .ql-tooltip[data-mode="image"]::before {
    content: "Image:";
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "Video:";
}