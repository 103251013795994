
.view-html .ql-size-small {
    font-size: 0.75em;
}

.view-html .ql-size-medium {
    font-size: 1.0em;
}

.view-html .ql-size-large {
    font-size: 1.5em;
}

a span.view-html {
    color: yellow;
}
