
.rpgui-project-details {
    overflow: hidden !important;
    position: relative;
    background-color: transparent !important;
    width: 75vw;
}

.rpgui-project-details .rpgui-container.content {
    overflow: auto !important;
    height: 75vh;
}

.rpgui-project-details * {
    font-size: clamp(12px, 2.75vw, 16px) !important;
}

@media (max-width: 600px) {
    .rpgui-project-details {
        width: 85vw;
    }
}

@media (max-height: 600px) {
    .rpgui-project-details > .rpgui-container {
        height: 85vh;
    }
}

.rpgui-media-container {
    width: 100%;
    margin-bottom: 2em;
}

.rpgui-project-details .section-content {
    margin: 10px;
}

.rpgui-project-details .rpgui-media-container figure div {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.rpgui-project-details .rpgui-media > *,
.rpgui-project-details .rpgui-media {
    width: fit-content;
    height: 100%;
    max-width: 100%;
    display: inline-block;
}

.rpgui-project-details .rpgui-media img {
    border: medium solid white;
}

.rpgui-project-details .fullscreen-button {
    width: fit-content !important;
    height: fit-content !important;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    margin: 5px;
}

.rpgui-project-details .fullscreen-button {
    position: relative;
    z-index: 101;
}

.rpgui-project-details .fullscreen-content {
    position: relative;
    z-index: 100;
}

.rpgui-project-details .is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 10000;
    display: inline-flex;
}

.rpgui-project-details .is-fullscreen .media-map {
    position: absolute;
    top: 0;
    left: 0;
}

.rpgui-project-details .is-fullscreen .fullscreen-media {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.rpgui-project-details .is-fullscreen .media {
    object-fit: contain;
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
}

.rpgui-project-details .is-fullscreen .fullscreen-exit {
    top: 0;
    left: 0;
}

.rpgui-project-details .is-fullscreen .fullscreen-map-info {
    top: 0;
    right: 0;
}

.rpgui-project-details .is-fullscreen .fullscreen-last {
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
}

.rpgui-project-details .is-fullscreen .fullscreen-next {
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
}

.rpgui-project-details .is-fullscreen .fullscreen-caption {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.rpgui-project-details :not(.hide-controls) :not(.show-controls):not(.hide-controls):hover > .fullscreen-button,
.rpgui-project-details .show-controls:hover .fullscreen-button {
    opacity: 1;
    animation: fade-in 0.25s linear;
}

.rpgui-project-details * > .fullscreen-button,
.rpgui-project-details .hide-controls .fullscreen-button {
    opacity: 0;
    animation: fade-out 0.25s linear;
}

@keyframes fade-in {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes fade-out {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

.fade {
    opacity: 1;
    animation: fade 0.25s linear;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.rpgui-project-details .fullscreen-button {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.rpgui-project-details .fullscreen-button button,
.rpgui-project-details .fullscreen-caption {
    padding: 5px;
}

.rpgui-project-details figure {
    width: fit-content;
    margin: auto;
}

.rpgui-project-details figure figcaption {
    margin-top: 0.75em;
    text-align: center;
}

.rpgui-project-details .controls {
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 9000;
    pointer-events: none;;
}

.rpgui-project-details,
.rpgui-project-details .controls {
    min-width: 400px;
}

.rpgui-project-details .controls .controls-container {
    height: fit-content;
    width: fit-content;;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 5px 0;
    pointer-events: auto;
    margin: auto;
}

.rpgui-project-details .controls .controls-container .control * {
    font-size: 20px;
}

.rpgui-project-details .controls .controls-container .control.incremental {
    padding-bottom: 3.3px;
    translate: 0.5px 1.5px;
}

.rpgui-project-details .controls .control-container.active {
    border: solid yellow 0.8px;
    border-radius: 2px;
}

.rpgui-project-details .controls .control-container:not(.active) {
    padding: 0.8px
}

.rpgui-project-details .controls .active .control {
    mix-blend-mode: darken;
    background-color: gray;
}

.rpgui-project-details .role ul {
    padding-left: 10px;
}

.rpgui-project-details .role ul li {
    padding: 10px;
    border-radius: 5px;
}

.rpgui-project-details .role .role-subsection {
    margin: 10px auto;
}

.rpgui-project-details .role .role-subsection h5 {
    text-align: left;
}
